import { FiCloud, FiCompass,FiCommand, FiBox, FiBell, FiMonitor,AiOutlineSolution,FcMultipleDevices } from '../assets/icons/vander'
import client1 from '../assets/images/team/01.jpg'
import client2 from '../assets/images/team/02.jpg'
import client3 from '../assets/images/team/03.jpg'
import client4 from '../assets/images/team/04.jpg'
import client5 from '../assets/images/team/05.jpg'
import client6 from '../assets/images/team/06.jpg'

import blog1 from '../assets/images/blog/01.jpg'
import blog2 from '../assets/images/blog/02.jpg'
import blog3 from '../assets/images/blog/03.jpg'

import company1 from '../assets/images/team/amazon.svg'
import company2 from '../assets/images/team/google.svg'
import company3 from '../assets/images/team/lenovo.svg'
import company4 from '../assets/images/team/paypal.svg'
import company5 from '../assets/images/team/shopify.svg'
import company6 from '../assets/images/team/spotify.svg'

export const servicesData = [
    {
        icon:AiOutlineSolution,
        title:'Enterprise Software Solutions',
        desc:'Software solutions designed for small, medium & large organizations to support their complex business processes, improve efficiency, and drive growth.'
    },
    {
        icon:FiCompass,
        title:'Custom Software Development',
        desc:'Tailored software solutions designed to meet specific business needs, including web applications, mobile apps, and desktop software.'
    },
    {
        icon:FiMonitor,
        title:'API Development and Integration',
        desc:'The development and integration of application programming interfaces (APIs) to enable communication between different software applications.'
    },
    {
        icon:FcMultipleDevices,
        title:'Web & Mobile App Development',
        desc:'Creating websites, web, and mobile applications for various platforms which utilizes the latest technologies and frameworks to ensure compatibility, performance, and user experience across different devices.'
    },
    {
        icon:FiCommand,
        title:'Legacy System Modernization',
        desc:'The process of updating and modernizing outdated software systems.'
    },
    {
        icon:FiBox,
        title:'Software Maintenance and Support',
        desc:'Services that ensure the ongoing maintenance and support of software applications.'
    },
    {
        icon:FiCloud,
        title:'Cloud Migration Services and Support',
        desc:'Services that help businesses migrate their IT infrastructure and applications to the cloud.'
    },
    {
        icon:FiBell,
        title:'IT Consulting and Strategy',
        desc:'Providing expert advice and guidance on technology adoption, digital transformation, and IT strategy planning to drive business growth and innovation.'
    },
]

export const clientData = [
    {
        image:client1,
        name:'Calvin Carlo',
        title:'Manager',
        desc:'" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. "'
    },
    {
        image:client2,
        name:'Christa Smith',
        title:'Manager',
        desc:'" The most well-known dummy text is the Lorem Ipsum, which is said to have originated in the 16th century. "'
    },
    {
        image:client3,
        name:'Jemina CLone',
        title:'Manager',
        desc:'" One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others"'
    },
    {
        image:client4,
        name:'Smith Vodka',
        title:'Manager',
        desc:'" Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts "'
    },
    {
        image:client5,
        name:'Cristino Murfi',
        title:'Manager',
        desc:'" There is now an abundance of readable dummy texts. These are usually used when a text is required"'
    },
    {
        image:client6,
        name:'Cristino Murfi',
        title:'Manager',
        desc:'" According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero "'
    },
]

export const blogData = [
    {
        image:blog1,
        title:'Design your apps in your own way',
        desc:'This is required when, for example, the final text is not yet available.',
        date:'13th March 2023'
    },
    {
        image:blog2,
        title:'How apps is changing the IT world',
        desc:'This is required when, for example, the final text is not yet available.',
        date:'5th May 2023'
    },
    {
        image:blog3,
        title:'Smartest Applications for Business',
        desc:'This is required when, for example, the final text is not yet available.',
        date:'19th June 2023'
    },
]

export const companyLogo = [company1, company2, company3, company4, company5, company6]