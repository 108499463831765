import React from "react";
// import { Link } from "react-router-dom";
// import { Link as Link1 } from "react-scroll";

import { sliderProps } from "../components/sliderProps";
import { Swiper, SwiperSlide } from "swiper/react";

// import hero1 from '../assets/images/home.png'

import NavLight from "../components/navLight";
import About from "../components/about";
import Services from "../components/services";
// import AgencyTab from "../components/agencyTab";
// import Cta from "../components/cta";
// import Client from "../components/client";
// import Pricing from "../components/pricing";
// import Blogs from "../components/blog";
import Footer from "../components/footer";
import Switcher from "../components/switcher";
import GetInTouch from "../components/getInTuoch";

import slideImg1 from '../assets/images/slider/shape3.png';

// const scrollBottom = () =>{
//     window.scrollTo({ 
//         top: 10000,  
//         behavior: 'smooth'
//       });
// }

export default function IndexSix(){

    return(
        <>
        <NavLight/>
        
        <div className="slider-list">
        <Swiper {...sliderProps.hero2}>
          <SwiperSlide>
            <div className="slider-area">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="slider-content style-two">
                      <div className="slider-sub-title style-two">
                        {/* <h4>IT Consulting</h4> */}
                      </div>
                      <div className="slider-main-title">
                        {/* <h1>Unlock Your Business's</h1> */}
                        {/* <h1>Full Potential with</h1>
                        <h1>Success Future</h1> */}
                      </div>
                      <div className="thrown-btn slider2">
                        {/* <Link legacyBehavior href="/contact-us">
                          <a className="header-button">
                            Read More
                            <span />
                          </a>
                        </Link> */}
                      </div>
                    </div>
                    <div className="slider-shape3 dance3">
                      <img src={slideImg1} alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slider-area two">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="slider-content style-two">
                      <div className="slider-sub-title style-two row">
                      {/* <h4> </h4>
                      <h4>Imagine Big ...</h4>
                      <h4> </h4> */}
                      </div>
                      <div className="slider-main-title">
                        {/* <h1>Unlock Your HR</h1>
                        <h1>Potential Strategic</h1>
                        <h1>Consulting Services</h1> */}
                      </div>
                      <div className="thrown-btn slider2">
                        {/* <Link legacyBehavior href="/contact-us">
                          <a className="header-button">
                            Read More
                            <span />
                          </a>
                        </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          {/* <SwiperSlide>
            <div className="slider-area three">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="slider-content style-two">
                      <div className="slider-sub-title style-two" > */}
                        {/* <h4>Smart IT Consulting</h4> */}
                      {/* </div> */}
                      {/* <div className="slider-main-title">
                        <h1>Unlock Your HR1111111111</h1>
                        <h1>Potential Strategic1111111</h1>
                        <h1>Consulting Services111111111</h1>
                      </div>
                      <div className="thrown-btn slider2">
                        <Link legacyBehavior href="/contact-us">
                          <a className="header-button">
                            Read More 11111111111
                            <span />
                          </a>
                        </Link>
                      </div> */}
                    {/* </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide> */}
          <SwiperSlide>
            <div className="slider-area four">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="slider-content style-two">
                      <div className="slider-sub-title style-two" >
                        {/* <h4>Smart IT Consulting</h4> */}
                      </div>
                      {/* <div className="slider-main-title">
                        <h1>Unlock Your HR1111111111</h1>
                        <h1>Potential Strategic1111111</h1>
                        <h1>Consulting Services111111111</h1>
                      </div>
                      <div className="thrown-btn slider2">
                        <Link legacyBehavior href="/contact-us">
                          <a className="header-button">
                            Read More 11111111111
                            <span />
                          </a>
                        </Link>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slider-area five">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="slider-content style-two">
                      <div className="slider-sub-title style-two" >
                        {/* <h4>Smart IT Consulting</h4> */}
                      </div>
                      {/* <div className="slider-main-title">
                        <h1>Unlock Your HR1111111111</h1>
                        <h1>Potential Strategic1111111</h1>
                        <h1>Consulting Services111111111</h1>
                      </div>
                      <div className="thrown-btn slider2">
                        <Link legacyBehavior href="/contact-us">
                          <a className="header-button">
                            Read More 11111111111
                            <span />
                          </a>
                        </Link>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slider-area six">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="slider-content style-two">
                      <div className="slider-sub-title style-two" >
                        {/* <h4>Smart IT Consulting</h4> */}
                      </div>
                      {/* <div className="slider-main-title">
                        <h1>Unlock Your HR1111111111</h1>
                        <h1>Potential Strategic1111111</h1>
                        <h1>Consulting Services111111111</h1>
                      </div>
                      <div className="thrown-btn slider2">
                        <Link legacyBehavior href="/contact-us">
                          <a className="header-button">
                            Read More 11111111111
                            <span />
                          </a>
                        </Link>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
        <About/>
        <Services/>
        {/* <AgencyTab/>
        <Cta/>
        <Client/>
        <Pricing/>
        <Blogs/> */}
        <GetInTouch/>
        <Footer/>
        <Switcher/>
        </>
    )
}